import { IconButton, InputAdornment, TextField, Typography, styled } from '@mui/material'
import React from 'react'

TextField.propTypes = {}
const StyledTextField = styled('div')(() => {
  return {
    width: '100%',
    '& p, & h5': {
      fontFamily: 'Be Vietnam Pro, sans-serif',
      fontStyle: 'normal',
      color: '#333333',
    },
    '& .title-input': {
      marginBottom: '8px',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#525252',
    },
  }
})
function MyTextField(props) {
  const [showPassword, setShowPassword] = React.useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  return (
    <StyledTextField>
      {props.title && <Typography className="title-input">
        {props.title}{' '}
        {props.require === 'true' && <Typography variant="span" style={{ color: '#FF3141' }}>
          *
        </Typography>}
      </Typography>}
      <TextField
        {...props}
        autoComplete="new-password"
        sx={{
          width: `${props.width || '100%'} !important`,
          backgroundColor: `${props.disabled === true || props.readonly === 'readonly' ? '#F5F5F5' : props.backgroundColor || '#FFFFFF'
            }`,
          color: `${props.textcolor || '#333333'}`,
          borderRadius: '6px',
          border: '1px solid #EEEEEE',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& input[readonly]': {
            cursor: 'pointer',
            userSelect: 'none',
          },
          '& .MuiOutlinedInput-root': {
            fontFamily: 'Be Vietnam Pro, sans-serif',
            fontStyle: 'normal',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
            color: '#525252',
            '& fieldset': {
              borderColor: '#EEEEEE',
            },
            '&:hover fieldset': {
              borderColor: '#EEEEEE',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#2563EB',
            },

          },
          '& input:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 50px white inset;',
            '-webkit-text-fill-color': '#333;',
          },

          '& input:-webkit-autofill:focus': {
            '-webkit-box-shadow': '0 0 0 50px white inset;',
            '-webkit-text-fill-color': '#333;',
          },
        }}
        name={props.name}
        type={showPassword ? 'text' : props.type}
        readOnly={props.readonly}
        disabled={props.disabled}
        value={props.value}
        placeholder={props.placeholder}
        variant="outlined"
        size="small"
        onChange={(e) => props.handleChange(e)}
        onBlur={props.onBlur}
        error={props.error}
        InputProps={{
          autoComplete: "new-password",
          startAdornment: <>{props.iconstart}</>,
          endAdornment: props.type === 'password' && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? (
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.232 0.857422C16.482 0.607422 16.8904 0.607422 17.1404 0.857422C17.3987 1.10742 17.3987 1.50742 17.1404 1.75742L1.75701 17.1408C1.63201 17.2658 1.47368 17.3324 1.30702 17.3324C1.14035 17.3324 0.982015 17.2658 0.857015 17.1491C0.607015 16.8991 0.607015 16.4908 0.857015 16.2408L3.30667 13.7898C2.56847 13.1127 1.88707 12.2984 1.29034 11.3656C0.457011 10.0573 0.457011 7.93226 1.29034 6.62393C2.25701 5.10726 3.44034 3.91559 4.75701 3.09893C6.07368 2.29893 7.51535 1.85726 8.99868 1.85726C10.6538 1.85726 12.2626 2.39932 13.6898 3.40101L16.232 0.857422ZM10.888 6.20434C10.3501 5.83948 9.70032 5.63226 8.99868 5.63226C7.13201 5.63226 5.63201 7.14059 5.63201 8.99893C5.63201 9.70031 5.84379 10.3499 6.20721 10.8877L6.92739 10.1671C6.73068 9.81979 6.61536 9.41982 6.61536 8.99889C6.61536 7.69056 7.68202 6.61556 8.99869 6.61556C9.41917 6.61556 9.81872 6.73064 10.1658 6.92696L10.888 6.20434ZM16.7236 6.64902C16.482 6.26569 16.2236 5.90736 15.957 5.57402C15.6486 5.18236 15.0653 5.14902 14.7153 5.49902L12.2153 7.99902C12.3986 8.54902 12.432 9.18236 12.2653 9.84069C11.9736 11.0157 11.0236 11.9657 9.84864 12.2574C9.1903 12.424 8.55697 12.3907 8.00697 12.2074L5.95697 14.2574C5.5403 14.674 5.67364 15.4074 6.23197 15.624C7.12364 15.9657 8.04864 16.1407 8.99863 16.1407C10.482 16.1407 11.9236 15.7074 13.2403 14.899C14.582 14.0657 15.7903 12.8407 16.7653 11.2824C17.557 10.024 17.5153 7.90736 16.7236 6.64902ZM11.382 8.99925C11.382 10.3076 10.3153 11.3826 8.99864 11.3826C8.94864 11.3826 8.90697 11.3826 8.85697 11.3659L11.3653 8.85758C11.382 8.90758 11.382 8.94925 11.382 8.99925Z" fill="#CCCCCC" />
                  </svg>

                ) : (
                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99959 0.857422C11.9663 0.857422 14.7829 2.59909 16.7079 5.62409C17.5413 6.93242 17.5413 9.06576 16.7079 10.3658C15.7413 11.8824 14.5579 13.0741 13.2413 13.8908C11.9246 14.6991 10.4829 15.1324 8.99959 15.1324C6.03293 15.1324 3.21626 13.3991 1.29126 10.3658C0.457926 9.05742 0.457926 6.93242 1.29126 5.62409C2.25793 4.10742 3.44126 2.91576 4.75793 2.09909C6.07459 1.29076 7.51626 0.857422 8.99959 0.857422ZM5.63293 7.99909C5.63293 9.85742 7.13293 11.3658 8.99959 11.3658C10.8663 11.3658 12.3663 9.85742 12.3663 7.99909C12.3663 6.14076 10.8663 4.63242 8.99959 4.63242C7.13293 4.63242 5.63293 6.14076 5.63293 7.99909ZM6.62461 7.99906C6.62461 6.68239 7.69128 5.61572 8.99961 5.61572C10.3079 5.61572 11.3829 6.69072 11.3829 7.99906C11.3829 9.30739 10.3079 10.3741 8.99961 10.3741C7.69128 10.3741 6.62461 9.30739 6.62461 7.99906Z" fill="#CCCCCC" />
                  </svg>

                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </StyledTextField>
  )
}

export default MyTextField
