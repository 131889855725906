import _ from 'lodash'
import moment from 'moment'

export default class LocalStorage {
  static get(key) {
    return JSON.parse(localStorage.getItem(key))
  }

  static set(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  static remove(key) {
    localStorage.removeItem(key)
  }

  static getToken = async () => {
    const data = await LocalStorage.get('token')
    return data || null
  }

  static setToken = async (token = null) => {
    await LocalStorage.set('token', token)
  }

  static getDataList = async (key) => {
    try {
      const dataStr = await LocalStorage.get(key)

      const data = JSON.parse(dataStr)
      if (!_.isEmpty(data) && !_.isEmpty(data.data) && moment().diff(moment(data.expiredTime)) < -300000) {
        return data
      }
      return null
    } catch (error) {
      return null
    }
  }

  static setDataList = async (key = '', data = { data: [], expiredTime: '' }) => {
    await LocalStorage.set(key, JSON.stringify(data))
  }
}
