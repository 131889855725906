/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  Divider,
  Stack,
  CircularProgress,
  TextField,
} from '@mui/material'
import { useParams } from 'react-router-dom'

// Icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

// Components
// import UserEditForm from './components/UserEditForm'
import CustomPagination from 'pages/logistic/components/CustomPagination'
import ListingTableHead from 'pages/logistic/pages/user/components/ListingTableHead'
import MainContent from 'components/MainContent'
import { useNavigate } from 'react-router-dom'

// Services
import * as logisticService from 'services/logistic.service'

// constants
import UserRoleLevel from 'constants/user-role-level'

import UiAvatar from 'components/ui/UiAvatar'
import { debounce } from 'lodash'
import UserListComponent from 'components/UserList'

const headCells = [
  {
    id: 'fullname',
    numeric: false,
    disablePadding: false,
    label: 'Nhân viên',
  },
  {
    id: 'username',
    numeric: false,
    disablePadding: true,
    label: 'Tên tài khoản',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Vai trò',
  },
  {
    id: 'campany',
    numeric: false,
    disablePadding: false,
    label: 'Phòng ban',
  },
]

const initPaging = {
  page: 1,
  rowsPerPage: 10,
  total: 0,
  keyword: '',
}

const reducerPaging = (state, action) => {
  switch (action.type) {
    case 'ADD_PAGE':
      return { ...state, page: action.data }
    case 'ADD_ROW_PER_PAGE':
      return { ...state, rowsPerPage: action.data }
    case 'ADD_TOTAL':
      return { ...state, total: action.data }
    case 'ADD_KEY_WORD':
      return { ...state, keyword: action.data }
    default:
      return state
  }
}

export default function UserListing() {
  const { level } = useParams()
  let navigate = useNavigate()
  const [isLoading, setIsLoading] = React.useState(true)
  const [data, setData] = React.useState([])
  const [statePaging, setStatePaging] = React.useReducer(reducerPaging, initPaging)
  const [keyword, setKeyword] = React.useState('')
  const [pagination, setPagination] = React.useState({})

  const handleGoBack = () => {
    navigate(-1)
  }

  const fetchData = async (page, perPage, keyword) => {
    setIsLoading(true)

    try {
      const data = await logisticService.adminGetAllUserByLevel(level, page, perPage, keyword)
      setData(data.data)
      setStatePaging({ type: 'ADD_TOTAL', data: data.pagination.total })
      setStatePaging({ type: 'ADD_PAGE', data: parseInt(data.pagination.current_page) })
      setStatePaging({ type: 'ADD_ROW_PER_PAGE', data: parseInt(data.pagination.per_page) })
      setPagination(data.pagination)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleChangePage = (event, newPage) => {
    setStatePaging({ type: 'ADD_PAGE', data: newPage })
    setPagination({ ...pagination, page: newPage })
  }

  const handleChangeRowsPerPage = (event) => {
    setStatePaging({ type: 'ADD_ROW_PER_PAGE', data: +event.target.value })
    setStatePaging({ type: 'ADD_PAGE', data: 1 })
    setPagination({ ...pagination, page: 1, per_page: +event.target.value })
  }

  React.useEffect(() => {
    fetchData(statePaging.page, statePaging.rowsPerPage, statePaging.keyword)
  }, [statePaging.page, statePaging.rowsPerPage, statePaging.keyword])

  const renderUserItem = (data, index) => {
    return (
      <TableRow sx={{ height: '52px' }} hover role="checkbox" tabIndex={-1} key={`user-item-` + data.id}>
        <TableCell sx={{ width: '30%' }} align="left">
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <UiAvatar src={data.avatar} />
            <b style={{ whiteSpace: 'nowrap' }}>{data.name}</b>
          </Stack>
        </TableCell>
        <TableCell sx={{ width: '10%' }}>
          <Typography> {data.username}</Typography>
        </TableCell>
        <TableCell sx={{ width: '30%' }} align="left" style={{ whiteSpace: 'nowrap' }}>
          {UserRoleLevel[data.role?.level]}
        </TableCell>
        <TableCell sx={{ width: '30%' }} align="left" style={{ whiteSpace: 'nowrap' }}>
          {data.company}
        </TableCell>
      </TableRow>
    )
  }

  const debounceSearch = React.useCallback(
    debounce((nextValue) => {
      setStatePaging({ type: 'ADD_PAGE', data: 1 })
      setStatePaging({ type: 'ADD_ROW_PER_PAGE', data: 10 })
      setStatePaging({ type: 'ADD_KEY_WORD', data: nextValue })
      setPagination({ ...pagination, page: 1, per_page: 10 })

    }, 500),
    []
  )

  const handleChange = (e) => {
    const { value } = e.target
    setKeyword(value)
    debounceSearch(value)
  }

  return (
    <UserListComponent
      headCells={headCells}
      renderUserItem={renderUserItem}
      isLoading={isLoading}
      data={data}
      isPagination={true}
      title="Danh sách nhân viên"
      toolbar={true}
      backBtn={true}
      handleClick={handleGoBack}

      pagination={pagination}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangeFilter={handleChange}
    />
  )
}
