

export const CheckboxIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="22" height="22" fill="white" fill-opacity="0.01" />
    <rect width="22" height="22" rx="4" fill="#2563EB" />
    <g clip-path="url(#clip0_1601_271350)">
      <rect width="22" height="22" fill="white" fill-opacity="0.01" />
      <path d="M8.78055 16.0404C8.59715 16.0404 8.42292 15.9604 8.29454 15.8204L3.86546 10.9908C3.59953 10.7009 3.59953 10.2209 3.86546 9.93092C4.13139 9.64095 4.57155 9.64095 4.83748 9.93092L8.78055 14.2305L17.1612 6.17451C17.4272 5.88454 17.8673 5.88454 18.1332 6.17451C18.3992 6.46449 18.3992 6.94444 18.1332 7.23441L9.26656 15.8204C9.13818 15.9604 8.96395 16.0404 8.78055 16.0404Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_1601_271350">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
