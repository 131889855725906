import { Box, Table, TableBody, TableCell, TableContainer, TableRow, useMediaQuery } from '@mui/material'

import { Player } from '@lottiefiles/react-lottie-player'
import { styled } from '@mui/material/styles'
import { NoData } from 'icons/no-data'
import ListingTableHead from 'pages/logistic/components/ListingTableHead'
import * as animationData from '../assets/loading.json'
import PaginationTable from './Pagination'
import { SearchNoData } from 'icons/SearchNoData'
import { useState } from 'react'
import { useEffect } from 'react'

const StyledTable = styled('div')(() => {
  return {
    '& p, & td, & th': {
      whiteSpace: 'nowrap',
      fontFamily: 'Be Vietnam Pro, sans-serif',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#737373',
      // marginLeft: '20px',

    },
    '& thead th': {
      color: '#333333'
    }
  }
})
function TableComponent(props) {
  const matches = useMediaQuery(`(max-width:1600px)`)
  const matchesMobile = useMediaQuery(`(max-width:600px)`)

  return (
    <StyledTable>
      <TableContainer
        sx={{
          maxHeight: `${props.maxHeight ? props.maxHeight : (matchesMobile ? 'calc(100vh - 288.2px)' : !matches ? 'calc(100vh - 326px)' : 'calc(100vh - 292.2px)')}`,
          borderRadius: '6px 6px 0 0'
        }}
      >
        <Table stickyHeader sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="small">
          <ListingTableHead
            headCells={props.headCells}
            checkbox={props.checkbox}
            isChecked={props.isChecked}
            handleSelectAllClick={props.handleSelectAllClick}
            indeterminate={props.indeterminate}
          />
          <TableBody>
            {props.isLoading && (
              <TableRow>
                <TableCell colSpan={props.headCells?.length}>
                  <Box sx={{
                    width: '100%',
                    margin: '60px auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                    <Player
                      autoplay
                      loop
                      src="/lottie_searching.json"
                      style={{ height: '314px', width: '640px' }}
                    >
                    </Player>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {!props.isLoading && (props.data?.length > 0 ? props.data?.map((row, index) => props.renderServicesItem(row, index)) :
              <TableRow>
                <TableCell colSpan={props.headCells?.length}><Box sx={{
                  width: '100%',
                  margin: '60px auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  {props.isSearch ? <SearchNoData /> : <NoData />}
                  <p style={{ marginTop: '24px', fontSize: '16px', lineHeight: '24px', color: '#737373' }}>{props.isSearch ? props.searchNoData : 'Không có dữ liệu'}</p>
                </Box></TableCell>
              </TableRow>)
            }</TableBody>
        </Table>


      </TableContainer>
      {(!props.isLoading && props.isPagination) && props.data?.length > 0 && (
        <PaginationTable
          pagination={props.pagination}
          handleChangePage={props.handleChangePage}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
        />
      )}
    </StyledTable>
  )
}

export default TableComponent
