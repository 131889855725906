import { delay, put, takeEvery } from 'redux-saga/effects'
import { layoutActions } from '../slices/layoutSlice'

function* showMessage(action) {
  try {
    yield delay(5000)
    yield put(layoutActions.hideMessage(action.payload.id))
  } catch {}
}

export default function* layoutSaga() {
  yield takeEvery(layoutActions.showMessage.type, showMessage)
}
