import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

//3th lib
import _ from 'lodash'
import MyTextField from './MainContent/components/TextField'

const EnhancedTableToolbar = (props) => {
  const { onChange } = props

  // const handleChange = _.debounce((e) => {
  //   onChange({ search: e.target.value })
  // }, 500)
  const matches = useMediaQuery(`(max-width:600px)`)
  return (
    <Box
      sx={{
        padding: `${matches ? '8px 16px' : '16px'}`,
        backgroundColor: '#FFFFFF',
        borderRadius: '6px',
        mb: `${matches ? '8px' : '24px'}`,
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.04)',
        mt: '24px',
        width: '100%'
      }}
    >
      <Box sx={{
        width: '25%',
        display: 'flex',
        alignItems: 'center',
      }}>
        {props.text && <Typography
          sx={{
            marginRight: '16px',
            fontFamily: 'Be Vietnam Pro, sans-serif',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#525252',
            maxWidth: '20%'
          }}
        >
          {props.text}
        </Typography>}
        <MyTextField
          width={matches ? '80%' : props.width}
          textcolor="#A3A3A3"
          placeholder={props.placeholder}
          backgroundColor="#F5F5F5"
          handleChange={onChange}
          iconstart={<SearchIcon sx={{ marginRight: '4px' }} color="disabled" />}
        />
      </Box>
    </Box>
  )
}

EnhancedTableToolbar.propTypes = {
  onChange: PropTypes.func,
}

export default EnhancedTableToolbar
