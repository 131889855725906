import { withStyles } from "@material-ui/core";
import { Tooltip } from "@mui/material";

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    fontSize: '14px',
    fontWeight: '400', fontFamily: 'Be Vietnam Pro, sans-serif',
    fontStyle: 'normal',
    lineHeight: '20px',
    filter: 'drop-shadow(0px 0px 30px rgba(51, 51, 51, 0.2))',
    padding: '8px 12px'
  },
  arrow: {
    color: 'white',
  },
}))(Tooltip);