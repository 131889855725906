import { createSlice } from '@reduxjs/toolkit'
import LocalStorage from 'utils/localStorage'

const initialState = {
  loading: false,
  list: [],
}

const categorySlice = createSlice({
  initialState,
  name: 'category',
  reducers: {
    getList: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    getListSuccess: (state, action) => {
      return {
        loading: false,
      }
    },
    getListError: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
  },
})

export const { actions: categoryActions, reducer: categoryReducer } = categorySlice
export const categorySelector = (state) => state.category
