import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

let isExist = (permision, code) => {
  if (permision) return permision.some((item) => item.module === code)
}

function ProtechPath({ moduleCode }) {
  const [ready, setReady] = useState(false)
  const userPermission = useSelector((state) => state.user.user?.role?.permissions)

  useEffect(() => {
    if (userPermission && isExist(userPermission, moduleCode)) {
      setReady(true)
    }
  }, [userPermission, moduleCode])

  if (!ready) {
    // Chờ userPermission được cập nhật từ API
    return null
  }

  if (userPermission && isExist(userPermission, moduleCode)) {
    return <Outlet />
  } else return <Navigate to="/404" />
}

export default ProtechPath
