import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material'
import { CheckboxIcon } from 'icons/checkbox'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

function ListingTableHead(props) {
  const { headCells, checkbox, } = props
  const { order, orderBy } = props
  return (
    <TableHead sx={{ height: '50px' }}>
      <TableRow>
        {checkbox && <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={props.indeterminate}
            checked={props.isChecked || false}
            onChange={(e) => props.handleSelectAllClick(e)}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
            icon={<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.5" width="21" height="21" rx="3.5" fill="white" stroke="#CCCCCC" />
            </svg>

            } checkedIcon={<CheckboxIcon />}
            indeterminateIcon={<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="22" height="22" fill="white" fill-opacity="0.01" />
              <g clip-path="url(#clip0_1716_276557)">
                <rect width="22" height="22" rx="4" fill="#2563EB" />
                <rect x="5.5" y="10.25" width="11" height="1.5" rx="0.75" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_1716_276557">
                  <rect width="22" height="22" fill="white" />
                </clipPath>
              </defs>
            </svg>
            }
          />
        </TableCell>}
        {headCells.map((headCell) => (
          <TableCell
            padding="checkbox"
            key={headCell.id}
            align={headCell.numeric === true ? 'right' : 'left'}
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              textAlign: headCell.textAlign,
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default ListingTableHead
