import fetchApi from './config'
import { transformObjToUrlQuery } from 'utils/common'

export const LOGISTIC_URL = process.env.REACT_APP_API_LOGISTIC_URL
const PORTAL_URL = process.env.REACT_APP_PORTAL_URL_API

let headers = {}

export const getshippingUnit = async (
  page = 1,
  perPage = 10,
  name = '',
  email = '',
  address = '',
  phone = '',
  search = ''
) => {
  let param = `${name !== '' && name ? '&name=' + name : ''}${email !== '' && email ? '&email=' + email : ''}${address !== '' && address ? '&address=' + address : ''
    }${phone !== '' && phone ? '&phone=' + phone : ''}${search !== '' ? '&search=' + search : ''}`
  return await fetchApi(`/v2/partners?page=${page}&per_page=${perPage}${param}`, {}, 'get', headers, LOGISTIC_URL)
}

export const getAllShippingUnit = async () => {
  return await fetchApi(`/v2/partners?true=all`, {}, 'get', headers, LOGISTIC_URL)
}

export const getUsers = async (page = 1, per_page = 10) => {
  return await fetchApi(`/users?page=${page}&per_page=${per_page}`, {}, 'get', headers, LOGISTIC_URL)
}

export const getLastCreatePackage = async () => {
  return await fetchApi(`/bill/last-time-create`, {}, 'get', headers, LOGISTIC_URL)
}

export const getListPackage = async (data) => {
  return await fetchApi(`/v2/packages`, data, 'post', headers, LOGISTIC_URL)
}

export const getPackage = (id) => {
  return fetchApi(`/v2/packages/${id}`, {}, 'get', headers, LOGISTIC_URL)
}

export const getHistoryPackage = (id) => {
  return fetchApi(`/v2/packages/${id}/histories`, {}, 'get', headers, LOGISTIC_URL)
}

export const listPackageInventory = (
  branch_from = [],
  page = 1,
  per_page = 10,
  billCode = '',
  branchToId = '',
  id = '',
  bill_and_pack = '',
  created_from,
  created_to,
  description = '',
  partner
) => {
  let params = `${billCode !== '' ? '&bill_code=' + billCode : ''}${branchToId !== '' ? '&branch_to=' + branchToId : ''
    }${id !== '' ? '&_id=' + id : ''}${bill_and_pack !== '' ? '&bill_and_pack=' + bill_and_pack : ''}${description !== 'undefined' && description !== '' ? '&description=' + description : ''
    }${created_from !== '' && created_from ? '&created_from=' + created_from : ''}${created_to ? '&created_to=' + created_to : ''
    }${partner && partner !== '' ? '&partner=' + partner : ''}`

  if (branch_from.length > 0) {
    for (const branch of branch_from) {
      const str = `&branch_from[]=${branch}`
      params = params + str
    }
  }

  return fetchApi(
    `/v2/packages/inventories?page=${page}&per_page=${per_page}${params}`,
    {},
    'get',
    headers,
    LOGISTIC_URL
  )
}

export const getListBill = async (branch_from) => {
  let params = ''

  if (branch_from.length > 0) {
    for (const branch of branch_from) {
      const str = `&branch_from[]=${branch}`
      params = params + str
    }
  }
  return await fetchApi(`/bill?all=true${params}`, {}, 'get', headers, LOGISTIC_URL)
}

export const getBill = async (code) => {
  return await fetchApi(`/bill?code=${code}`, {}, 'get', headers, LOGISTIC_URL)
}

export const getListBillByBranchFromAndPartner = async (branch_from, partner) => {
  let params = ''

  if (branch_from.length > 0) {
    for (const branch of branch_from) {
      const str = `&branch_from[]=${branch}`
      params = params + str
    }
  }

  return await fetchApi(`/bill?all=true${params}&partner=${partner}`, {}, 'get', headers, LOGISTIC_URL)
}

export const getAllBill = async () => {
  return await fetchApi(`/bill?all=true`, {}, 'get', headers, LOGISTIC_URL)
}

export const syncBill = async (data) => {
  return await fetchApi(`/v2/management/webtt/bills/sync`, data, 'post', headers, LOGISTIC_URL)
}

export const getAllBillByBranch = async (
  branch_to,
  code,
  description,
  created_from,
  created_to,
  export_date,
  branch_from,
  partner,
  status
) => {
  let param = `${branch_to !== '' && branch_to ? '&branch_to=' + branch_to : ''}${code !== '' && code ? '&code=' + code : ''
    }${description !== '' && description ? '&description=' + description : ''}${created_from !== '' && created_from ? '&created_from=' + created_from : ''
    }${created_to !== '' && created_to ? '&created_to=' + created_to : ''}${export_date !== '' && export_date ? '&export_date=' + export_date : ''
    }${partner !== '' && partner ? '&partner=' + partner : ''}${status !== '' && status ? '&status=' + status : ''}`

  if (branch_from.length > 0) {
    for (const branch of branch_from) {
      const str = `&branch_from[]=${branch}`
      param = param + str
    }
  }
  return await fetchApi(`/bill?all=true${param}`, {}, 'get', headers, LOGISTIC_URL)
}

export const getBillByFilter = async (
  page = 1,
  per_page = 10,
  branch_to,
  code,
  description,
  created_from,
  created_to,
  branch_from = [],
  export_date,
  partner,
  status
) => {
  let param = `${branch_to !== '' && branch_to ? '&branch_to=' + branch_to : ''}${code !== '' && code ? '&code=' + code : ''
    }${description !== '' && description ? '&description=' + description : ''}${created_from !== '' && created_from ? '&created_from=' + created_from : ''
    }${created_to !== '' && created_to ? '&created_to=' + created_to : ''}${export_date !== '' && export_date ? '&export_date=' + export_date : ''
    }${partner !== '' && partner ? '&partner=' + partner : ''}${status !== '' && status ? '&status=' + status : ''}`

  if (branch_from.length > 0) {
    for (const branch of branch_from) {
      const str = `&branch_from[]=${branch}`
      param = param + str
    }
  }

  return await fetchApi(`/bill?page=${page}&per_page=${per_page}${param}`, {}, 'get', headers, LOGISTIC_URL)
}

export const exportExcelByFillter = async (branch_to, code, description, created_from, created_to, branch_from) => {
  let param = `${branch_to !== '' && branch_to ? '&branch_to=' + branch_to : ''}${code !== '' && code ? '&code=' + code : ''
    }${description !== '' && description ? '&description=' + description : ''}${created_from !== '' && created_from ? '&created_from=' + created_from : ''
    }${created_to !== '' && created_to ? '&created_to=' + created_to : ''}`

  if (branch_from.length > 0) {
    for (const branch of branch_from) {
      const str = `&branch_from[]=${branch}`
      param = param + str
    }
  }
  return await fetchApi(`/bill/export-by-filter?all=true${param}`, {}, 'get', headers, LOGISTIC_URL)
}

export const getListCommand = async (branches = [], filter) => {
  let params = ''
  if (branches.length > 0) {
    params = branches.map(branch => `branches[]=${branch}`).join('&');
  }
  return await fetchApi(`/v2/commands${filter ? '' : '?'}${transformObjToUrlQuery(filter)}&${params}`, {}, 'get', headers, LOGISTIC_URL)
}

export const createCommand = (data) => {
  return fetchApi(`/v2/commands`, data, 'post', headers, LOGISTIC_URL)
}

export const editCommand = (id, data) => {
  return fetchApi(`/v2/commands/${id}`, data, 'put', headers, LOGISTIC_URL)
}

export const getCommand = async (id) => {
  return await fetchApi(`/v2/commands/${id}`, {}, 'get', headers, LOGISTIC_URL)
}

export const addPackage = (id, data) => {
  return fetchApi(`/v2/commands/${id}/packages/add`, data, 'put', headers, LOGISTIC_URL)
}

export const removePackage = (id, data) => {
  return fetchApi(`/v2/commands/${id}/packages/delete`, data, 'put', headers, LOGISTIC_URL)
}

export const exportHistoryPackage = (data) => {
  return fetchApi(`/v2/packages/history/export`, data, 'post', headers, LOGISTIC_URL)
}
export const exportPackage = (data) => {
  return fetchApi(`/v2/packages/export`, data, 'post', headers, LOGISTIC_URL)
}

export const exportExcel = (data) => {
  return fetchApi(`/bill/export`, data, 'post', headers, LOGISTIC_URL)
}

export const exportShippingOrderExcel = (data) => {
  return fetchApi(`/v2/commands/export/excel`, data, 'post', headers, LOGISTIC_URL)
}

export const getAllStore = async () => {
  return await fetchApi(`/v2/stores?all=true`, {}, 'get', headers, LOGISTIC_URL)
}

export const syncStoreLogistic = async () => {
  return await fetchApi(`/v2/stores/sync`, {}, 'get', headers, LOGISTIC_URL)
}

export const updateStore = async (id, data) => {
  return await fetchApi(`/v2/stores/${id}`, data, 'put', headers, LOGISTIC_URL)
}

export const getStore = async (page = 1, per_page = 10, search) => {
  let param = `${search !== '' && search ? '&search=' + search : ''}`
  return await fetchApi(`/stores?page=${page}&per_page=${per_page}${param}`, {}, 'get', headers, PORTAL_URL)
}

export const getStoreLogistic = async (page = 1, per_page = 10, search) => {
  let param = `${search !== '' && search ? '&search=' + search : ''}`
  return await fetchApi(`/v2/stores?page=${page}&per_page=${per_page}${param}`, {}, 'get', headers, LOGISTIC_URL)
}
export const scanQR = (data) => {
  return fetchApi(`/v2/commands/packages/qr/web-scan`, data, 'post', headers, LOGISTIC_URL)
}

export const addPartner = (data) => {
  return fetchApi(`/v2/partners`, data, 'post', headers, LOGISTIC_URL)
}

export const getRole = async () => {
  return await fetchApi(`/v2/roles`, {}, 'get', headers, LOGISTIC_URL)
}

export const getRoleDetail = async (id) => {
  return await fetchApi(`/v2/roles/${id}`, {}, 'get', headers, LOGISTIC_URL)
}

export const getMenuRole = async (id) => {
  return await fetchApi(`/v2/roles/menu/${id}`, {}, 'get', headers, LOGISTIC_URL)
}

export const createRole = async (data) => {
  return await fetchApi(`/v2/roles`, data, 'post', headers, LOGISTIC_URL)
}

export const adminUpdateMenuRole = async (data) => {
  return await fetchApi(`/v2/admin/menu/update-role`, data, 'put', headers, LOGISTIC_URL)
}

export const adminGetAllUserPortal = async (page, perPage, keyword) => {
  let param = `${keyword !== undefined && keyword !== '' ? '&search=' + keyword : ''}`
  return await fetchApi(
    `/v2/management/portal/users?page=${page}&per_page=${perPage}${param}`,
    {},
    'get',
    headers,
    LOGISTIC_URL
  )
}

export const adminGetAllUsersPortal = async () => {
  return await fetchApi(`/v2/management/portal/users?all=true`, {}, 'get', headers, LOGISTIC_URL)
}

export const adminGetAllUserByLevel = async (level, page, perPage, keyword) => {
  let param = `${keyword !== undefined && keyword !== '' ? '&name=' + keyword : ''}`
  return await fetchApi(
    `/v2/management/users?level=${level}&page=${page}&per_page=${perPage}${param}`,
    {},
    'get',
    headers,
    LOGISTIC_URL
  )
}

export const adminGetUser = async (id) => {
  return await fetchApi(`/v2/management/users/${id}`, {}, 'get', headers, LOGISTIC_URL)
}

export const adminUpdateUser = async (id, data) => {
  return await fetchApi(`/v2/management/portal/users/${id}`, data, 'put', headers, LOGISTIC_URL)
}

export const adminGetUserPortal = async (id) => {
  return await fetchApi(`/v2/management/portal/users/${id}`, {}, 'get', headers, LOGISTIC_URL)
}

export const adminUpdateUserPortal = async (data) => {
  return await fetchApi(`/v2/management/portal/users`, data, 'put', headers, LOGISTIC_URL)
}

export const adminSyncUserPortal = async (data) => {
  return await fetchApi(`/v2/management/portal/users/sync`, data, 'GET', headers, LOGISTIC_URL)
}

export const getAllTrips = async (filter) => {

  return await fetchApi(`/trip/list${transformObjToUrlQuery(filter)}`, {}, 'get', headers, LOGISTIC_URL)
}

export const getAllTransfers = async (filter) => {

  return await fetchApi(`/transfers/${transformObjToUrlQuery(filter)}`, {}, 'get', headers, LOGISTIC_URL)
}
export const getTransferDetail = async (id, export_excel) => {
  return await fetchApi(`/transfers/${id}` + (export_excel ? `/export` : ''), {}, 'get', headers, LOGISTIC_URL)
}

export const getTransferWhitelistStores = async () => {

  return await fetchApi(`/transfers/whitelist-stores`, {}, 'get', headers, LOGISTIC_URL)
}
export const updateTransferWhitelistStores = async (ids) => {
  return await fetchApi(`/transfers/whitelist-stores`, {
    ids
  }, 'patch', headers, LOGISTIC_URL)
}

export const getTripDetail = async (id) => {
  return await fetchApi(`/v2/trips/${id}`, {}, 'get', headers, LOGISTIC_URL)
}

export const createPartnerUser = async (data) => {
  return await fetchApi(`/v2/partners`, data, 'post', headers, LOGISTIC_URL)
}

export const getPartnerUser = async (id) => {
  return await fetchApi(`/v2/partners/${id}/users`, {}, 'get', headers, LOGISTIC_URL)
}

export const updateShippingUnit = async (data) => {
  return await fetchApi(`/v2/packages/bulk-update-transport-unit`, data, 'post', headers, LOGISTIC_URL)
}


export const deleteCommands = async (id) => {
  return await fetchApi(`v2/commands/${id}`, {}, 'delete', {}, LOGISTIC_URL)
}
export const markSendNotiBill = async (id, send_noti) => {
  return await fetchApi(`/bill/${id}/mark-send-noti?send_noti=${send_noti}`, {}, 'get', headers, LOGISTIC_URL)
}
