import { Navigate, Outlet, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import useAuth from 'hooks/useAuth'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { checkIsLocal } from 'utils/common'

const ProtectedPage = () => {
  const location = useLocation()
  const userInfo = useSelector((state) => state.user.user)
  const isAuthenticated = useAuth()

  const hasEnoughUserInfomation = userInfo?.stores && _.isArray(userInfo?.stores) && userInfo?.stores?.length > 0

  if (isAuthenticated) {
    if (location.pathname.search('/profile') <= -1 && !hasEnoughUserInfomation) {
      return <Navigate to="/profile" />
    }

    return <Outlet />
  } else {
    if (checkIsLocal()) {
      return <Navigate to="/login" />
    } else {
      window.location.href = `${process.env.REACT_APP_FORLIFE_HOME}/login?redirect_url=${window.location.href}`
    }
  }


}

ProtectedPage.propTypes = {
  allowedRoles: PropTypes.array,
}

export default ProtectedPage
