
import {
  Box,
  useTheme
} from '@mui/material'
import Button from '@mui/material/Button'
import { styled as MUIStyled } from '@mui/material/styles'

const HeaderMainContent = MUIStyled(({ className, ...otherProps }) => {
  const theme = useTheme()
  const { headerTitle, subTitle, btn, handleClick } = otherProps
  return (
    <Box
      className="w-full flex flex-row justify-between items-center"
      sx={{
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          alignItems: 'start',
        },
        '& .header-title': {
          fontWeight: '700',
          fontSize: '22px',
          lineHeight: '28px',
          color: '#525252'
        },
        '& .sub-title': {
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '16px',
          color: '#737373',
          mt: '4px',
          '& b': {
            color: '#333333'
          }
        }
      }}
    >
      <Box>
        <Box sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
          {otherProps.backBtn && <Button size='small'
            onClick={() => {
              handleClick()
            }}
            sx={{
              borderRadius: '6px',
              border: '1px solid #EEEEEE',
              backgroundColor: '#FFFFFF',
              padding: '10.5px 12px',
              mr: '24px',
              width: '43px',
              height: '40px',
              minWidth: '0',
              '&:hover': {

                backgroundColor: '#FFFFFF',
              }
            }}>
            <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.40286 9.67399C5.50911 9.78024 5.6437 9.82982 5.77828 9.82982C5.91286 9.82982 6.05453 9.78024 6.1537 9.67399C6.35911 9.46857 6.35911 9.12857 6.1537 8.92315L2.7607 5.53015H13.5204C13.8108 5.53015 14.0516 5.28932 14.0516 4.9989C14.0516 4.70848 13.8108 4.46765 13.5204 4.46765H2.76086L6.1537 1.07482C6.35911 0.869401 6.35911 0.529401 6.1537 0.323984C5.94828 0.118568 5.60828 0.118568 5.40286 0.323984L1.10328 4.62357C0.897865 4.82899 0.897865 5.16898 1.10328 5.3744L5.40286 9.67399Z" fill="#333333" />
            </svg>
          </Button>}
          <p className="text-2xl font-normal leading-10 header-title">{headerTitle}</p>
        </Box>
        {subTitle && <p className='sub-title' dangerouslySetInnerHTML={{ __html: subTitle }}></p>}
      </Box>
      {btn}
    </Box>
  )
})(({ theme }) => ({}))

export default HeaderMainContent
