import {
  Box,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Stack,
  TableContainer,
  CircularProgress,
  Paper,
  Typography,
  IconButton,
} from '@mui/material'
import Button from '@mui/material/Button'
import ListingTableHead from '../../components/ListingTableHead'
import { isEmpty } from 'lodash'
import { styled as MUIStyled } from '@mui/material/styles'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import MainContent from '../../../../components/MainContent'
import * as logisticService from 'services/logistic.service'
import HeaderMainContent from 'components/MainContent/components/Header'
import TableComponent from 'components/Table'
import { CustomTooltip } from 'components/TooltipCustom'
import { IconEdit } from 'icons/iconEdit'
import { DetailIcon } from 'icons/detailIcon'
import CreateRole from './CreateRole'
import { headerHeight } from 'config'

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Tên vai trò',
  },
  {
    id: 'customer',
    numeric: true,
    textAlign: 'left',
    disablePadding: false,
    label: 'Danh sách nhân viên',
  },
  {
    id: 'decentralization',
    numeric: true,
    textAlign: 'right',
    disablePadding: false,
    label: 'Thao tác',
  },
]

const ListRole = MUIStyled(({ className }) => {
  let navigate = useNavigate()
  const theme = useTheme()
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [open, setOpen] = useState(false)

  const fetchData = async (page, per_page) => {
    setIsLoading(true)

    try {
      const data = await logisticService.getRole()
      setData(data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleAddnewRole = () => {
    // navigate(`/logistic/role/role-managerment`)
    setOpen(true)
  }

  const handleShowListUser = (level) => {
    navigate(`/logistic/role/user-managerment/${level}`)
  }

  const renderRole = (data, index) => {
    return (
      <TableRow sx={{ height: '52px' }} hover role="checkbox" tabIndex={-1} key={data.id}>
        <TableCell align="left" component="th" scope="row">
          {data?.name}
        </TableCell>


        <TableCell align="left" component="th" scope="row">
          <Box sx={{ display: 'flex', alignItems: 'center', '& p': { color: '#2560E5', } }}>
            <Typography
              sx={{ fontSize: '14px', fontWeight: 400, cursor: 'pointer', mr: '4px' }}
              onClick={() => {
                handleShowListUser(data?.level)
              }}
            >
              Danh sách

            </Typography>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8335 14.666C1.8335 14.3899 2.05735 14.166 2.3335 14.166H13.6668C13.943 14.166 14.1668 14.3899 14.1668 14.666C14.1668 14.9422 13.943 15.166 13.6668 15.166H2.3335C2.05735 15.166 1.8335 14.9422 1.8335 14.666Z" fill="#2560E5" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0201 1.98043C12.8248 1.78517 12.5082 1.78517 12.313 1.98043L2.97962 11.3138C2.78436 11.509 2.78436 11.8256 2.97962 12.0209C3.17488 12.2161 3.49146 12.2161 3.68672 12.0209L13.0201 2.68754C13.2153 2.49228 13.2153 2.17569 13.0201 1.98043Z" fill="#2560E5" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1665 2.33398C13.1665 2.05784 12.9426 1.83398 12.6665 1.83398H5.81984C5.54369 1.83398 5.31984 2.05784 5.31984 2.33398C5.31984 2.61013 5.54369 2.83398 5.81984 2.83398H12.1665V9.18065C12.1665 9.45679 12.3904 9.68065 12.6665 9.68065C12.9426 9.68065 13.1665 9.45679 13.1665 9.18065V2.33398Z" fill="#2560E5" />
            </svg>

          </Box>
        </TableCell>

        <TableCell align="right" component="th" scope="row">
          <CustomTooltip title="Chỉnh sửa" arrow bottom

          >
            <IconButton
              size="small"
              onClick={() => {
                handleEditRole(data?.id)
              }}
              sx={{
                padding: '0',
                '&:hover': {
                  padding: 0,
                },
                backgroundColor: '#F5F5F5',
                borderRadius: '4px',
                width: '28px',
                height: '28px',
                m: '0 6px'
              }}
            >
              <IconEdit />

            </IconButton>

          </CustomTooltip>
        </TableCell>
      </TableRow>
    )
  }

  const handleEditRole = (id) => {
    navigate(`/logistic/role/role-managerment/${id}`)
  }

  return (
    <div className={['shipping-order-page', className].join()}>
      <MainContent>
        <Box
          direction="column"
          justifyContent="flex-end"
          sx={{
            minHeight: `calc(100vh - ${headerHeight}px)`,

            padding: '32px 32px 52px'
          }}
        >
          <Box xs={12}>
            <Box
              justifyContent="center"
              alignItems="center"
              sx={{
                [theme.breakpoints.down('sm')]: {
                  padding: '16px',
                },
              }}
            >
              <HeaderMainContent headerTitle='Danh sách vai trò'
                btn={<>
                  <Button
                    variant="outlined"
                    onClick={handleAddnewRole}
                    sx={{
                      color: '#fff',
                      background: '#2560E5',
                      borderColor: '#2560E5',
                      '&:hover': {
                        borderColor: '#2560E5',
                        background: '#2560E5',
                      },
                      padding: '7px 12px',
                      '& span': {
                        fontSize: '16px',
                        lineHeight: '24px'
                      }
                    }}
                  >
                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.49935 0.916016C3.59643 0.916016 0.416016 4.09643 0.416016 7.99935C0.416016 11.9023 3.59643 15.0827 7.49935 15.0827C11.4023 15.0827 14.5827 11.9023 14.5827 7.99935C14.5827 4.09643 11.4023 0.916016 7.49935 0.916016ZM10.3327 8.5306H8.0306V10.8327C8.0306 11.1231 7.78977 11.3639 7.49935 11.3639C7.20893 11.3639 6.9681 11.1231 6.9681 10.8327V8.5306H4.66602C4.3756 8.5306 4.13477 8.28977 4.13477 7.99935C4.13477 7.70893 4.3756 7.4681 4.66602 7.4681H6.9681V5.16602C6.9681 4.8756 7.20893 4.63477 7.49935 4.63477C7.78977 4.63477 8.0306 4.8756 8.0306 5.16602V7.4681H10.3327C10.6231 7.4681 10.8639 7.70893 10.8639 7.99935C10.8639 8.28977 10.6231 8.5306 10.3327 8.5306Z" fill="white" />
                    </svg>

                    <span style={{ marginLeft: '8px' }}>Tạo mới</span>
                  </Button>
                </>} />

              <Paper className="w-full flex flex-col" sx={{ mt: '24px', filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.04))', borderRadius: '6px', boxShadow: 'none' }}>

                <TableComponent
                  headCells={headCells}
                  renderServicesItem={renderRole}
                  isLoading={isLoading}
                  data={data}
                  isPagination={false}
                />
              </Paper>
              <CreateRole
                open={open}
                onClose={() => {
                  setOpen(false)
                }}
                created={() => {
                  fetchData()
                  setOpen(false)
                }}
              />

            </Box>
          </Box>
        </Box>
      </MainContent>
    </div>
  )
})(({ theme }) => ({}))

export default ListRole
