/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Box,
  Paper,
  TableCell,
  TableRow,
  TextField,
  Typography,
  useTheme
} from '@mui/material'

import { styled as MUIStyled } from '@mui/material/styles'
import { MESSAGE_TYPES } from 'constants/common'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as logisticService from 'services/logistic.service'
import { createMessage } from 'utils/common'
import MainContent from '../../../../components/MainContent'
import '../../style.css'
import TableComponent from 'components/Table'

import { ChipCustom } from 'components/ChipCustom'
import HeaderMainContent from 'components/MainContent/components/Header'
import { headerHeight } from 'config'
import { useParams } from 'react-router-dom'
import { formatDate } from 'utils/formater'
import { HistoryPackageIcon } from 'icons/HistoryPackageIcon'
import { isEmpty } from 'lodash'

const headCells = [
  {
    id: 'time',
    numeric: false,
    textAlign: 'left',
    disablePadding: true,
    label: 'Thời gian',
  },
  {
    id: 'user',
    numeric: false,
    textAlign: 'left',
    disablePadding: false,
    label: 'Người thực hiện',
  },
  {
    id: 'action',
    numeric: false,
    textAlign: 'left',
    disablePadding: false,
    label: 'Hành động',
  },
]


const PackageDetail = MUIStyled(({ className }) => {
  const { id } = useParams()
  const userStores = useSelector((state) => state.store.stores)
  const selectedStore = useSelector((state) => state.store.selectedStore)
  let navigate = useNavigate()
  const [data, setData] = useState([])
  const [history, setHistory] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const theme = useTheme()
  const dispatch = useDispatch()

  const fetchData = async (id) => {
    setIsLoading(true)

    try {
      const data = await logisticService.getPackage(id)
      setData(data)
      setIsLoading(false)
    } catch (error) {
      dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: error.message }))

      setIsLoading(false)
    }
  }

  const fetchDataHistory = async (id) => {

    try {
      const data = await logisticService.getHistoryPackage(id)
      setHistory(data)
    } catch (error) {
      dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: error.message }))
    }
  }

  const handleGoBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    if (id) {
      fetchData(id)
      fetchDataHistory(id)
    }
  }, [id])

  const processItem = (item) => {
    let content = item.content;
    let link = "";

    if (item.module === "COMMAND" && item.action === "ADD") {
      const commandId = item.command ? item.command.id : null;
      if (commandId) {
        link = `/logistic/shipping_order/management_shipping_order/${commandId}`;
      }
    } else if (item.module === "TRIP" && item.action === "ADD") {
      const tripId = item.trip ? item.trip.id : null;
      if (tripId) {
        link = `/logistic/trip/${tripId}`;
      }
    }

    if (link) {
      const startIndex = content.indexOf("(");
      const endIndex = content.indexOf(")");
      if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
        const textWithinParentheses = content.substring(startIndex + 1, endIndex);
        content = content.replace(
          `(${textWithinParentheses})`,
          `<a style="font-size: 14px; color: #2560E5;" href="${link}">${textWithinParentheses}</a>`
        );
      }
    }

    return { ...item, content };
  }

  const renderItem = (data, index) => {
    let newData = processItem(data)

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={data.id}>
        <TableCell sx={{ padding: '16px' }} align="left" component="th" scope="row">
          {newData?.created_at}
        </TableCell>

        <TableCell sx={{ padding: '16px' }} align="left" component="th" scope="row">
          {isEmpty(newData?.action_by) || newData?.action_by === null ? "" : newData?.action_by?.name} 
        </TableCell>

        <TableCell sx={{ padding: '16px' }} align="left" component="th" scope="row">
          <span dangerouslySetInnerHTML={{ __html: newData?.content }} />
        </TableCell>
      </TableRow>
    )
  }


  return (
    <div className={['package-page', className].join()}>
      <MainContent>
        <Box
          direction="column"
          justifyContent="flex-end"
          sx={{
            minHeight: `calc(100vh - ${headerHeight}px)`,
            padding: '32px 32px 52px'
          }}
        >
          <Box xs={12}>
            <Box
              justifyContent="center"
              alignItems="center"
              sx={{
                [theme.breakpoints.down('sm')]: {
                  padding: '16px',
                },
              }}
            >
              <HeaderMainContent headerTitle='Chi tiết kiện' backBtn={true} handleClick={handleGoBack}
              />
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '24px', paddingTop: '20px' }}>
                <Paper sx={{ width: '50%', filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.04))', borderRadius: '6px', boxShadow: 'none' }}>
                  <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <img src="/package-detail.png" alt="" style={{ margin: 'auto' }} />
                    <Typography sx={{
                      fontWeight: '500', fontSize: '16px',
                      lineHeight: '24px', color: '#333333'
                    }}>Kiện hàng {data && data.code}</Typography>
                  </Box>
                  <Box sx={{
                    padding: '16px 20px',
                    '& .MuiPaper-root': {
                      boxShadow: 'none',
                      height: '52px',
                      borderBottom: '1px solid #EEEEEE',
                      '&:before': {
                        backgroundColor: 'transparent'
                      }
                    },
                    '& p': {
                      color: '#737373'
                    }
                  }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '24px', padding: '24px 0', alignItems: 'center' }}>
                      <Box sx={{ width: '30%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                        <Typography >Trạng thái</Typography>
                      </Box>
                      <Box sx={{ width: '70%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                        <ChipCustom data={data} />
                      </Box>
                    </Box>

                    <PackageDetailItem title="Mã kiện" data={data && data.code} />
                    <PackageDetailItem title="Mã phiếu xuất" data={data && data?.bill_code?.code} />
                    <PackageDetailItem title="Chi nhánh xuất" data={data && data?.branch_from?.name} />
                    <PackageDetailItem title="Chi nhánh Nhận" data={data && data?.branch_to?.name} />
                    <PackageDetailItem title="Ngày CT xuất" data={formatDate(data && data?.export_date)} />
                    <PackageDetailItem title="Ngày tạo" data={formatDate(data && data?.created_at)} />
                  </Box>
                </Paper>

                <Paper sx={{ width: '50%', filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.04))', borderRadius: '6px', boxShadow: 'none' }}>
                  <Box sx={{ background: '#EDE9FE', padding: '16px', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', borderRadius: '4px' }}>
                    <HistoryPackageIcon />
                    <Typography sx={{ fontSize: '14px', fontWeight: '700!important', lineHeight: '20px' }}>Lịch sử hoạt động</Typography>
                  </Box>
                  <TableComponent
                    headCells={headCells}
                    renderServicesItem={renderItem}
                    isLoading={isLoading}
                    type="shipping_order"
                    data={history}
                    isPagination={false}
                  />
                </Paper>
              </Box>
            </Box>
          </Box>
        </Box>
      </MainContent>
    </div>
  )
})(({ theme }) => ({}))

const BoxWrapper = MUIStyled(Box)`
  background-color: rgb(247, 249, 252);
`

const PackageDetailItem = ({ title, data }) => {
  return <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '24px', paddingBottom: '24px', alignItems: 'center' }}>
    <Box sx={{ width: '30%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
      <Typography>{title}</Typography>
    </Box>
    <Box sx={{
      width: '70%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center',
      '& .MuiInputBase-root': {
        fontSize: '14px',
      },
      '& .Mui-disabled': {
        background: '#F5F5F5'
      },
      '& fieldset': {
        top: '-10px'
      }
    }}>
      <TextField size='small' fullWidth value={data} disabled />
    </Box>
  </Box>
}

export default PackageDetail
