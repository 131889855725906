// material-ui
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'

// project import
import Profile from './Profile'
// import Notification from './Notification'
import SelectStore from './SelectStore'

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = ({ open }) => {
  const userStores = useSelector((state) => state.store.stores)
  return (
    <>
      {/* {!matchesXs && <Search />} */}
      <Box sx={{ width: `${open ? '260px' : '65px'}` }}></Box>

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px 32px',
        width: `${open ? 'calc(100% - 260px)' : 'calc(100% - 65px)'}`
      }}>
        <SelectStore />
        {/* <Notification /> */}
        <Profile />
      </Box>
      {/* {!matchesXs && <Profile />} */}
      {/* {matchesXs && <MobileSection />} */}
    </>
  )
}

export default HeaderContent
