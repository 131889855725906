import { Box, MenuItem, Select, Typography, useMediaQuery } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import PropTypes from 'prop-types'

const PaginationTable = ({ pagination, onRowsPerPageChange, handleChangePage }) => {
  const matches = useMediaQuery(`(max-width:600px)`)
  return (
    <Box
      sx={{
        height: `${matches ? 'auto' : '52px'}`,
        display: `${matches ? 'block' : 'flex'}`,
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: '1px solid #EEEEEE',
        '& p, & button': {
          fontFamily: 'Be Vietnam Pro, sans-serif',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '20px',
          color: '#737373',
        },
        '& button': {
          color: '#333333'
        },
        p: `${matches ? '8px 12px' : '0 18px'}`,
      }}
    >
      {matches && (
        <Box
          sx={{
            marginBottom: '12px',
            justifyContent: 'center',
            '& .MuiPagination-ul li': {
              margin: '0 4px',
              minWidth: '28px',
              height: '28px',
              backgroundColor: 'rgba(255, 255, 255, 1e-05);',
              border: '1px solif #EEEEEE',
              borderRadius: '4px',
              color: '#333333',
            },
            '& .MuiPagination-ul': {
              justifyContent: 'center'
            },
            '& .Mui-selected': {
              backgroundColor: '#DB1B33 !important',
              border: '1px solid #DB1B33',
              color: 'white',
            },
            '& .MuiPaginationItem-previousNext': {
              border: 'none',
            },
          }}
        >
          <Pagination
            page={pagination.current_page || 1}
            count={pagination.last_page}
            variant="outlined"
            shape="rounded"
            size="small"
            onChange={(e, page) => {
              handleChangePage(e, page)
            }}
          />
        </Box>
      )}
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: `${matches ? 'center' : 'center'}`,
          }}
        >
          <Typography>Số hàng trên trang: </Typography>
          <Select
            sx={{
              height: '28px',
              padding: '3px 5px 3px 12px',
              minWidth: '65px',
              ml: 1,
              border: '1px solid #EEEEEE',
              borderRadius: '6px',
              '& fieldset': {
                border: 'none'
              },
              '& div:first-child': {
                p: 0,
                paddingRight: '0 !important'
              }
            }}
            size="small"
            value={`${pagination.per_page}`}
            onChange={(e) => {
              onRowsPerPageChange(e)
            }}
          // IconComponent={() => (
          //   <Box sx={{
          //     width: '16px',
          //     height: '16px',
          //     display: 'flex',
          //     alignItems: 'center',
          //     mr: '5px'
          //   }} >
          //     <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          //       <path d="M8.94845 0.455078H4.79512H1.05512C0.415119 0.455078 0.0951188 1.22841 0.548452 1.68174L4.00179 5.13508C4.55512 5.68841 5.45512 5.68841 6.00845 5.13508L7.32178 3.82174L9.46179 1.68174C9.90845 1.22841 9.58845 0.455078 8.94845 0.455078Z" fill="#737373" />
          //     </svg>
          //   </Box>

          // )}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={50}>50</MenuItem>

            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box>
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          {pagination.from <= pagination.to ?
            <Typography>
              {!matches
                ? ` Hiển thị ${pagination.from}-${pagination.to} trên tổng sổ ${pagination.total}`
                : `Hiển thị ${pagination.from}-${pagination.to} / ${pagination.total}`}
            </Typography> : <Typography>
              {!matches
                ? ` Hiển thị 0-0 trên tổng sổ ${pagination.total}`
                : `Hiển thị 0-0 / ${pagination.total}`}
            </Typography>
          }
        </Box>
        {!matches && (
          <Box
            sx={{
              m: `${matches ? '12px 0' : ''}`,
              justifyContent: 'center',
              '& .MuiPagination-ul li': {
                margin: '0 4px',
                minWidth: '28px',
                height: '28px',
                backgroundColor: 'rgba(255, 255, 255, 1e-05);',
                border: '1px solif #EEEEEE',
                borderRadius: '4px',
                color: '#333333',
              },
              '& .Mui-selected': {
                backgroundColor: '#DB1B33 !important',
                border: '1px solid #DB1B33',
                color: 'white',
              },
              '& .MuiPaginationItem-previousNext': {
                border: 'none',
              },
            }}
          >
            <Pagination
              page={pagination.current_page || 1}
              count={pagination.last_page || 1}
              variant="outlined"
              shape="rounded"
              size="small"
              onChange={(e, page) => {
                handleChangePage(e, page)
              }}
            />
          </Box>
        )}
      </Box>

    </Box>
  )
}

PaginationTable.propTypes = {
  pagination: PropTypes.node,
}

export default PaginationTable
