/* eslint-disable react-hooks/exhaustive-deps */

import {
  Box,
  Paper
} from '@mui/material'

// Icons

// Components
import { styled } from '@mui/material/styles'
import TableComponent from './Table'
import DialogUserEditForm from './ui/UiDialog/DialogEditForm'
import Header from 'layout/MainLayout/Header'
import EnhancedTableToolbar from './Toolbar'
import HeaderMainContent from './MainContent/components/Header'
import _ from 'lodash'

// Services

// constants



const StyledTableUserListing = styled('div')(() => {
  return {

    padding: '32px 32px 52px',
    '& .title': {
      fontFamily: 'Be Vietnam Pro, sans-serif',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '1.5rem',
      lineHeight: '2rem',
      color: '#525252',
    },
    '& p': {
      whiteSpace: 'nowrap',
      fontFamily: 'Be Vietnam Pro, sans-serif',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      color: '#333333',
      // marginLeft: '20px'
    },
  }
})

export default function UserListComponent(props) {

  return (

    <StyledTableUserListing>
      <HeaderMainContent headerTitle={props.title || "Danh sách nhân viên"} btn={props.btn} backBtn={props.backBtn} handleClick={props.handleClick} />
      {props.toolbar && <EnhancedTableToolbar placeholder="Tìm kiếm theo mã, họ tên..." onChange={(val) => props.handleChangeFilter(val)} />}
      <Paper sx={{ width: '100%', mt: `${props.toolbar ? '8px' : '24px'}`, filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.04))', borderRadius: '6px', boxShadow: 'none' }}>
        <TableComponent
          headCells={props.headCells}
          renderServicesItem={props.renderUserItem}
          isLoading={props.isLoading}
          data={props.data}
          isPagination={_.isBoolean(props.isPagination) ? props.isPagination : true}
          pagination={props.pagination}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
        />
      </Paper>

      {props.isEdit && props.openDrawer && (
        <DialogUserEditForm
          key={props.openDrawerData?.id || ''}
          id={props.userEditId}
          onUpdated={() => {
            props.setOpenDrawer(false)
            props.fetchData()
          }}
          handleClose={() => {
            props.setOpenDrawer(false)
          }}
          open={props.openDrawer}
          dataDrawer={props.openDrawerData}
        />
      )}
    </StyledTableUserListing>

  )
}
