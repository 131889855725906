import { Alert, Box, Snackbar, styled } from '@mui/material'
import { footerToolbarHeight } from 'config'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'


const MainContent = styled(({ className, children, footerToolbar }) => {
  const { messages } = useSelector((state) => state.layout, shallowEqual)
  return (
    <Box
      className={['main-content', className].join(' ')}
      sx={(theme) => ({
      })}
    >
      <Box
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            paddingTop: '8px',
            paddingBottom: '8px',
          },
          position: 'relative',
          '& .MuiSnackbar-root': {
            position: 'absolute'
          }
        })}
      >
        {children}
        <Snackbar key={Object.keys(messages)[0]} open={!(Object.keys(messages).length === 0 && messages.constructor === Object)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}>
          <div style={{
            display: 'flex', flexDirection: 'column',
            minWidth: '260px',
            alignItems: 'center',
            minHeight: '44px',
            marginBottom: '24px',
          }}>
            {Object.values(messages)
              .sort((a, b) => a.createdAt > b.createdAt)
              .map((msg, index) => (
                <Alert
                  variant="outlined"
                  severity={msg.type}
                  key={`notification-message-${index}`}
                  icon={msg.type === 'success' ? <CheckCircleIcon /> : <CancelIcon />}
                  sx={{
                    width: '100%',
                    backgroundColor: `${msg.type === 'success' ? '#ECFDF5' : '#FFF1F2'}`,
                    p: '10px 16px',
                    border: `${msg.type === 'success' ? '1px solid #A7F3D0' : '1px solid #FECDD3'}`,
                    '& .MuiAlert-icon': {
                      marginRight: '10px',
                      padding: 0,
                      alignItems: 'center',
                      '& svg': {
                        width: '16px',
                        height: '16px',
                      },
                    },
                    '& .MuiAlert-message': {
                      fontWeight: '400',
                      fontSize: '16px',
                      lineHeight: '24px',
                      fontFamily: 'Be Vietnam Pro, sans-serif',
                      fontStyle: 'normal',
                      color: '#333333',
                      p: 0
                    },
                  }}
                >
                  {msg.content}
                </Alert>
              ))}
          </div>
        </Snackbar>
      </Box>
      {footerToolbar && <Box className="footer-toolbar">{footerToolbar}</Box>}
    </Box>
  )
})(({ theme }) => ({
  '& .footer-toolbar': {
    boxShadow: '0px -6px 32px rgba(0, 0, 0, 0.04)',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    height: '72px',
    padding: '16px 32px',
    display: 'flex',
    alignItems: 'flex-end',
    backdropFilter: 'blur(15px)',
    borderTop: '1px solid #EEEEEE',
    flexDirection: 'column'
  },
}))

MainContent.propTypes = {
  footerToolbar: PropTypes.any,
}

export default MainContent
