import PropTypes from 'prop-types'
import { useRef, useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'

// project import
import MainCard from 'components/MainCard'
import Transitions from 'components/@extended/Transitions'

// assets
import { EditOutlined, LogoutOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from 'redux/slices/authSlice'
import { userSlector } from 'redux/slices/userSlice'
import UiAvatar from 'components/ui/UiAvatar'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { clearLocalStorage, createMessage } from 'utils/common'
import { MESSAGE_TYPES } from 'constants/common'
import _ from 'lodash'
import UserRoleLevel from 'constants/user-role-level'
import { HomeEditProfile } from 'icons/homeEditProfile'
import { Logout } from 'icons/logout'

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const user = useSelector(userSlector)
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleLogout = async () => {
  //   await localStorage.clear()

  //   // logout
  //   dispatch(authActions.logout())
  // }

  const handleLogout = async () => {
    dispatch(authActions.logout())
  }


  useEffect(() => {
    if (_.isArray(user?.user?.stores) && user?.user?.stores.length === 0) {
      dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'Vui lòng chọn vị trí' }))
    }
  }, [])

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75, }}>
      <Tooltip title="">
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          sx={{
            '&:hover': {
              backgroundColor: 'transparent'
            },
            ml: 2
          }}
          disableRipple
        >
          <Stack direction="row" spacing={1} alignItems="center" sx={{}}>
            <UiAvatar
              name={user?.user?.name || user?.user?.username}
              src={user?.user?.avatar}
              style={{ width: 40, height: 40 }}
            />
            <Stack
              sx={(theme) => ({
                [theme.breakpoints.down('sm')]: {
                  display: 'none',
                },
                '& .username': {
                  fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#333333',
                  display: 'flex',
                  alignItems: 'center'
                },
                '& .role': {
                  fontSize: '12px', lineHeight: '16px', color: '#737373', m: 0
                }
              })}
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0.5}
            >
              <Typography className='username'>
                {user?.user?.name || user?.user?.username}
                <Box sx={{ ml: '4px' }}>
                  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.99904 6.19846C5.53237 6.19846 5.0657 6.01846 4.71237 5.66513L0.365703 1.31846C0.17237 1.12513 0.17237 0.80513 0.365703 0.611797C0.559036 0.418464 0.879037 0.418464 1.07237 0.611797L5.41904 4.95846C5.73904 5.27846 6.25904 5.27846 6.57904 4.95846L10.9257 0.611797C11.119 0.418464 11.439 0.418464 11.6324 0.611797C11.8257 0.80513 11.8257 1.12513 11.6324 1.31846L7.2857 5.66513C6.93237 6.01846 6.4657 6.19846 5.99904 6.19846Z" fill="#333333" />
                  </svg>
                </Box>
              </Typography>
              <Typography className='role'>
                {UserRoleLevel[user?.user?.role?.level]}
              </Typography>
            </Stack>
          </Stack>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: '276px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 0px 30px rgba(51, 51, 51, 0.2))',
            mt: 2.5,
            backgroundColor: '#FFFFFF',
            borderRadius: '8px',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 24,
              width: 15,
              height: 15,
              bgcolor: '#FFF',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
            '& ul': {
              padding: 0,
              '& li': {
                padding: '14px 20px',
                '& span': {
                  fontFamily: 'Be Vietnam Pro, sans-serif',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#333333'
                },
                '&:hover': {
                  backgroundColor: 'transparent'
                },
              },
              '& .MuiListItemIcon-root': {
                minWidth: '0',
                width: '20px',
                marginRight: '8px'
              }
            }
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={(event) => {
          navigate('/profile')
          handleClose()
        }}>

          <ListItemIcon>
            <HomeEditProfile />
          </ListItemIcon>
          <ListItemText primary="Thông tin tài khoản" />

        </MenuItem>
        {/* <MenuItem onClick={handleLogout} sx={{
          borderTop: '1px solid #EEEEEE'
        }}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          Đăng xuất
        </MenuItem> */}
      </Menu>

    </Box>
  )
}

export default Profile
