import { REHYDRATE } from 'redux-persist'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { storeActions } from 'redux/slices/storeSlice'
import { userActions } from 'redux/slices/userSlice'
import * as userService from '../../services/user.service'

/**
 * Get user info by token
 * @param {*} param0
 */
function* getUserToken({ payload }) {
  try {
    const res = yield call(userService.getUserTransportToken)
    yield put(userActions.getUserTokenSuccess(res))
    yield put(storeActions.getUserStoreSuccess(res))

    if (res.stores?.length > 0) {
      const obj = JSON.parse(localStorage.getItem('stores'))
      yield put(storeActions.userSelectedStore(obj ? obj.arr : [res?.stores[0]?.id]))
    }
    if (payload && payload.onSuccess) {
      payload.onSuccess(res)
    }
  } catch (error) {
    yield put(userActions.getUserTokenError(error))
    if (payload && payload.onError) {
      payload.onError(error)
    }
  }
}
function* watchRehydrate() {
  yield takeEvery(REHYDRATE, getUserToken)
}

function* getUserInfoWorker() {
  yield takeLatest(userActions.getUserToken.type, getUserToken)
}
export default function* userSaga() {
  yield all([watchRehydrate(), getUserInfoWorker()])
}
