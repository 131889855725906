export const HomeEditProfile = () => {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3867_55666)">
    <rect width="20" height="20" fill="white" fill-opacity="0.01"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.20785 5.83256C5.20785 8.47423 7.35785 10.6242 9.99951 10.6242C12.6412 10.6242 14.7912 8.47423 14.7912 5.83256C14.7912 3.19089 12.6412 1.04089 9.99951 1.04089C7.35785 1.04089 5.20785 3.19089 5.20785 5.83256ZM6.45785 5.83256C6.45785 3.88256 8.04951 2.29089 9.99951 2.29089C11.9495 2.29089 13.5412 3.88256 13.5412 5.83256C13.5412 7.78256 11.9495 9.37423 9.99951 9.37423C8.04951 9.37423 6.45785 7.78256 6.45785 5.83256ZM12.3496 18.6245C12.5662 18.8412 12.8662 18.9579 13.1829 18.9579C13.2496 18.9579 13.3079 18.9495 13.3745 18.9328L14.4996 18.7746C14.7912 18.7329 15.1412 18.5579 15.3495 18.3495L17.8122 15.8869C17.8549 15.8541 17.8935 15.8157 17.9267 15.7724L18.2996 15.3995C18.7412 14.9578 18.9579 14.5162 18.9579 14.0412C18.9579 13.5745 18.7329 13.1079 18.2996 12.6745C17.7912 12.1662 16.7495 11.4912 15.5662 12.6745L12.6162 15.6245C12.4079 15.8412 12.2329 16.1829 12.1912 16.4745L12.0329 17.5995C11.9746 17.9912 12.0912 18.3662 12.3496 18.6245ZM17.4162 14.5162L17.267 14.6654C16.8478 14.4631 16.507 14.1213 16.306 13.7014L16.4495 13.5579C16.6412 13.3662 16.7746 13.2662 16.9246 13.2662C17.0662 13.2662 17.2162 13.3579 17.4162 13.5579C17.6079 13.7412 17.7079 13.9162 17.7079 14.0412C17.7079 14.1912 17.5496 14.3828 17.4162 14.5162ZM16.356 15.5764C15.9732 15.3252 15.6449 14.9963 15.3944 14.613L13.4996 16.5079C13.4746 16.5329 13.4329 16.6162 13.4329 16.6495L13.2829 17.6912L14.3246 17.5412C14.3579 17.5329 14.4412 17.4912 14.4662 17.4662L16.356 15.5764ZM2.21631 18.3326C2.21631 18.6742 2.49964 18.9576 2.84131 18.9576C3.18298 18.9576 3.46631 18.6742 3.46631 18.3242C3.46631 15.4492 6.39966 13.1159 9.99966 13.1159C10.7913 13.1159 11.558 13.2326 12.2913 13.4492C12.6163 13.5492 12.9663 13.3659 13.0663 13.0326C13.1663 12.7076 12.983 12.3576 12.6497 12.2576C11.808 12.0076 10.908 11.8742 9.99966 11.8742C5.708 11.8742 2.21631 14.7742 2.21631 18.3326Z" fill="#737373"/>
    </g>
    <defs>
    <clipPath id="clip0_3867_55666">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
}