import { makeStyles } from "@material-ui/core"
import { Chip } from "@mui/material"

const useStyles = makeStyles((theme) => ({
  color: {
    fontFamily: 'Be Vietnam Pro, sans-serif',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    width: 'auto',
    height: '24px',
    border: 'none',
    '&.WAIT_FOR_HANDOVER': { //chờ tạo lệnh
      background: '#FEF3C7',
      color: '#D97706'
    },
    '&.WAIT_FOR_TRANSPORT': {//chờ vận chuyển
      background: '#F3E8FF',
      color: '#8B5CF6',
    },
    '&.TRANSPORTING': { //Đang vận chuyển
      background: '#FFEDD5',
      color: '#EA580C'
    },
    '&.WAIT_FOR_EXPORT': {//Chờ nhập kho
      background: '#FFE4E6',
      color: '#F43F5E'
    },
    '&.HANDOVERED': {// Đang bàn giao
      background: '#DCFCE7',
      color: '#16A34A'
    },
    '&.IMPORTED': {// đã nhận hàng
      background: '#DBEAFE',
      color: '#2563EB'
    },
    '&.UNKNOWN': {// không xác định
      background: '#EEEEEE',
      color: '#737373'
    },
    '&.UNIMPORT': { //Chưa nhận hàng
      background: '#F9FAFB',
      color: '#4B5563'
    },

  },
}))
const Status = {
  0: 'WAIT_FOR_HANDOVER',
  1: 'WAIT_FOR_EXPORT',
  2: 'WAIT_FOR_TRANSPORT',
  3: 'TRANSPORTING',
  4: 'HANDOVERED',
  5: 'IMPORTED',
  6: 'UNKNOWN',
  7: 'UNIMPORT'
}
export const ChipCustom = (props) => {
  const classes = useStyles()
  const { data } = props
  return <Chip
    label={data?.status_str}
    className={`${classes.color} ${Status[data.status]
      }`}
    sx={{
      borderRadius: '3px',
      textTransform: 'none',
    }}
    variant="outlined"
  />
}