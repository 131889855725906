/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import * as logisticService from 'services/logistic.service'
import { useFormik } from 'formik'
import { MESSAGE_TYPES } from 'constants/common'
import { createMessage } from 'utils/common'
import { isEmpty } from 'lodash'
import { Box, FormHelperText, TableCell, TableRow, TextField, Typography } from '@mui/material'
import * as Yup from 'yup'
import InputField from 'pages/logistic/components/InputField'
import { useDispatch } from 'react-redux'
import MyTextField from 'components/MainContent/components/TextField'
import { useNavigate } from 'react-router-dom'

export default function MaxWidthDialog({ open, onClose, created, ...props }) {
  const [data, setData] = useState([])
  let navigate = useNavigate()

  const partnerSchema = () => {
    return Yup.object().shape({
      name: Yup.string().trim().required('Không được bỏ trống tên quyền'),

    })
  }

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: partnerSchema(),
    onSubmit: (values) => {
      handleCreateRole(values)
    },
  })


  const handleCreateRole = async (value) => {
    let data = {
      name: value.name.trim(),
    }

    try {
      await logisticService.createRole(data).then((res) => {
        dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'Phân quyền thành công' }))
        created()
      })
    } catch (error) {
      dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: error.message }))
    }
  }


  const { values, errors, touched, handleBlur, handleChange } = formik

  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={onClose} sx={{
        '& .MuiDialog-container': {
          height: `calc(100vh - -28px)`,
          alignItems: 'flex-start',
          '& .MuiDialog-paper': {
            marginTop: '24px'
          }
        },
        '& p, & h2': {
          fontFamily: 'Be Vietnam Pro, sans-serif',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '20px'
        }
      }}>
        <DialogTitle sx={{
          textAlign: 'center', height: '72px',
          borderBottom: '1px solid #EEEEEE',
          display: 'flex',
          alignItems: 'center',
          '& p': {
            fontWeight: '500', fontSize: '16px',
            lineHeight: '24px', color: '#525252',
          }
        }}>
          <Typography sx={{
            width: '100%',
          }}>
            Tạo mới phân quyền
          </Typography>
        </DialogTitle>
        <DialogContent sx={{
          padding: '16px 20px',
          paddingTop: '16px !important',
          '& .MuiFormHelperText-root': {
            fontSize: '13px',
            lineHeight: '131%',
            color: '#FF3141',
            marginTop: '4px'
          }
        }}>
          <form className="w-full" onSubmit={formik.handleSubmit} autoComplete={false}>
            <MyTextField
              title="Phân quyền"
              handleChange={handleChange}
              error={Boolean(touched.name && errors.name)}
              onBlur={handleBlur}
              name="name"
              value={values.name}
              placeholder="Nhập tên quyền"
              disabled={false}
            />
            {errors.name && touched.name && <FormHelperText error>{errors.name}</FormHelperText>}
          </form>
        </DialogContent>
        <DialogActions sx={{
          padding: '16px 20px',
          borderTop: '1px solid #EEEEEE'
        }}>
          <Button onClick={onClose} sx={{
            color: '#333333',
            background: '#F5F5F5',
            borderColor: '#EEEEEE',
            borderRadius: '4px',
            '&:hover': {
              borderColor: '#EEEEEE',
              background: '#F5F5F5',
            },
            padding: '7px 12px',
            '& span': {
              fontSize: '16px',
              lineHeight: '24px'
            }
          }}
          >Huỷ bỏ</Button>
          <Button
            variant="outlined"
            sx={{
              color: '#fff',
              background: '#2560E5',
              borderColor: '#2560E5',
              borderRadius: '4px',
              '&:hover': {
                borderColor: '#2560E5',
                background: '#2560E5',
              },
              padding: '7px 12px',
              '& span': {
                fontSize: '16px',
                lineHeight: '24px'
              }
            }}
            onClick={formik.handleSubmit}
          >
            Lưu
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
