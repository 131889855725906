import { transformObjToUrlQuery } from 'utils/common'
import fetchApi from './config'

let portalUrl = process.env.REACT_APP_PORTAL_URL_API

export const getList = async (data = { name: '', paginate: 1 }) => {
  return await fetchApi(`/stores${transformObjToUrlQuery(data)}`, {}, 'get', {}, portalUrl)
}

export const createStore = async (data) => {
  return await fetchApi(`/stores`, data, 'post')
}

export const updateStore = async (id, data) => {
  return await fetchApi(`/stores/${id}`, data, 'put')
}

export const deleteStore = async (id) => {
  return await fetchApi(`/stores/${id}`, {}, 'delete')
}
