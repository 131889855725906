import jsCookies from 'js-cookie'
const SHARE_COOKIE_DOMAIN = process.env.REACT_APP_SHARE_COOKIE_DOMAIN || '127.0.0.1'

export default class Cookies {
  static get(key) { }

  static set(key, value) { }

  static remove(key) {
    return jsCookies.remove(key)
  }

  static removeToken = () => {
    return jsCookies.remove('token', { path: '/', domain: SHARE_COOKIE_DOMAIN })
  }

  // static setToken = (token, expiresIn) => {
  //   return jsCookies.set('token', token, { expires: parseInt(expiresIn), domain: SHARE_COOKIE_DOMAIN })
  // }
  static setToken = async (token, expiresIn) => {
    await jsCookies.set('token', token, { expires: parseInt(expiresIn), domain: SHARE_COOKIE_DOMAIN })
    this.remove('token')
    await jsCookies.set('token', token, { expires: parseInt(expiresIn), domain: SHARE_COOKIE_DOMAIN, secure: true })
  }

  static getRefreshToken = () => {
    const data = jsCookies.get('refresh_token')

    return data || null
  }

  static setRefreshToken = async (token, expiresIn) => {
    this.remove('refresh_token')
    await jsCookies.set('refresh_token', token, { expires: parseInt(expiresIn), domain: SHARE_COOKIE_DOMAIN, secure: true })
  }



  static getToken = () => {
    const data = jsCookies.get('token')

    return data || null
  }
}
