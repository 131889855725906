const { createSlice } = require('@reduxjs/toolkit')

const initialState = {
  stores: [],
  selectedStore: [],
}

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    getUserStore: (state) => {
      return {
        ...state,
      }
    },
    getUserStoreSuccess: (state, action) => {
      return {
        ...state,
        stores: action.payload.stores,
      }
    },
    getUserStoreError: (state, action) => {
      return {
        ...state,
      }
    },
    userSelectedStore: (state, action) => {
      return {
        ...state,
        selectedStore: action.payload,
      }
    },
    clearStore: (state) => {
      return {
        stores: [],
        selectedStore: '',
      }
    },
  },
})
export const { actions: storeActions, reducer: storeReducer } = storeSlice
