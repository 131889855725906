import { domainForlifeHome } from 'config'
import { call, put, takeLatest } from 'redux-saga/effects'
import Cookies from 'utils/cookies'
import * as authService from '../../services/auth.service'
import { authActions } from '../slices/authSlice'
import { storeActions } from '../slices/storeSlice'
import { userActions } from '../slices/userSlice'

const login = function* ({ payload }) {
  const { meta, ...data } = payload

  try {
    const res = yield call(authService.login, data)

    Cookies.setToken(res.access_token)
    Cookies.setRefreshToken(res.refresh_token)

    yield put(authActions.loginSuccess(data))

    if (meta && meta.onSuccess) {
      meta.onSuccess(res)
    }
  } catch (error) {
    yield put(authActions.loginError(error))
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

const logout = function* ({
  payload = {
    onSuccess: () => { },
    onError: () => { },
  },
}) {
  const { onSuccess, onError } = payload

  try {
    yield call(authService.logout)

    Cookies.remove('token')
    Cookies.remove('refresh_token')
    yield put(storeActions.clearStore())
    yield put(userActions.clearMenu())
    if (window.location.href.indexOf('/login') === -1) window.location.href = domainForlifeHome

    if (onSuccess) onSuccess()
  } catch (error) {
    if (onError) onError()
  }
}

export default function* authSaga() {
  yield takeLatest(authActions.login.type, login)
  yield takeLatest(authActions.logout.type, logout)
}
