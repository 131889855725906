import { useSelector } from 'react-redux'
import { userSlector } from 'redux/slices/userSlice'

const useAuth = () => {
  const user = useSelector(userSlector)

  return user?.isLoggedIn
}

export default useAuth
